





import { DcommNetwork } from '@/js/DcommNetwork'
import 'reflect-metadata'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TestNetBanner extends Vue {
    get isVisible() {
        let network = this.$store.state.Network.selectedNetwork
        if (!network) return false
        let netId = parseInt(network.networkId)

        if (netId == 1) return false
        return true
    }
}
