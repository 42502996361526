




























import { Vue, Component } from 'vue-property-decorator'

const SIZE = 24
@Component
export default class MnemonicPasswordInput extends Vue {
    keysIn: string = ''
    sortedKeys: string[] = []
    // words = Array.from(''.repeat(24))

    sortKey() {
        this.sortedKeys = this.keysIn.split(' ')
        this.$emit('change', this.keysIn.trim())
    }
    onFocus(ev: any) {
        ev.target.setAttribute('type', 'text')
    }

    onBlur(ev: any) {
        ev.target.setAttribute('type', 'password')
    }

    onPaste(e: any) {
        e.preventDefault()
    }

    onInput(ev: any, index: number) {
        const val: string = ev.target.value.trim()
        const words: string[] = val.split(' ').filter((w) => w !== '')

        if (words.length > 1) {
            words.forEach((word, i) => {
                const wordIndex = index + i
                if (wordIndex >= SIZE) return

                //@ts-ignore
                const dom = this.$refs[`in_${wordIndex}`][0]
                dom.value = word
                dom.focus()
            })
        }
    }

    emitValue() {
        let val = ''
        for (var i = 0; i < SIZE; i++) {
            //@ts-ignore
            const input = this.$refs[`in_${i}`][0]
            val += `${input.value} `
        }

        this.$emit('change', val.trim())
    }
}
