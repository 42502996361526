
































import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'
import DcommAsset from '@/js/DcommAsset'
import BalanceRow from './BalanceRow.vue'
import CollectibleTab from './CollectibleTab.vue'
import { UTXO } from '@dcomm-tech/dcomm-js/dist/apis/dvm'

@Component({
    components: {
        BalanceRow,
        CollectibleTab,
    },
})
export default class BalancePopup extends Vue {
    @Prop() assets!: DcommAsset[]
    @Prop({ default: false }) isNft?: boolean
    @Prop({ default: () => [] }) disabledIds!: string[] // asset id | if nft the utxo id

    isActive: boolean = false

    select(asset: DcommAsset) {
        if (asset.amount.isZero()) return
        if (this.isDisabled(asset)) return

        this.$emit('select', asset)
    }

    selectNFT(utxo: UTXO) {
        this.$emit('select', utxo)
        this.closePopup()
    }

    isDisabled(asset: DcommAsset): boolean {
        if (this.disabledIds.includes(asset.id)) return true
        return false
    }

    closePopup() {
        this.isActive = false
        this.$emit('close')
    }
}
