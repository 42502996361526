






















import 'reflect-metadata'
import { Vue, Component, Prop, Ref, Model } from 'vue-property-decorator'

import BalancePopup from '@/components/misc/BalancePopup/BalancePopup.vue'
import DcommAsset from '@/js/DcommAsset'
import DvmTokenSelect from '@/components/modals/DvmTokenSelect.vue'

@Component({
    components: {
        DvmTokenSelect,
        BalancePopup,
    },
})
export default class BalanceDropdown extends Vue {
    isPopup: boolean = false

    @Prop({ default: () => [] }) disabled_assets!: DcommAsset[]
    @Prop({ default: false }) disabled!: boolean
    @Model('change', { type: DcommAsset }) readonly asset!: DcommAsset

    get assetArray(): DcommAsset[] {
        // return this.$store.getters.walletAssetsArray
        return this.$store.getters['Assets/walletAssetsArray']
    }

    $refs!: {
        popup: BalancePopup
        token_modal: DvmTokenSelect
    }

    get disabledIds(): string[] {
        let disabledIds = this.disabled_assets.map((a) => a.id)
        return disabledIds
    }

    get symbol() {
        let sym = this.asset.symbol
        return sym
    }

    // get isPopup(){
    //     if(this.balancePopup){
    //         return this.balancePopup.isActive;
    //     }
    //     return false;
    // }

    showPopup() {
        this.$refs.token_modal.open()
        // this.balancePopup.isActive = true
        // this.isPopup = true
    }

    onclose() {
        // this.isPopup = false
    }

    onselect(asset: DcommAsset) {
        // this.selected = asset;
        // this.balancePopup.isActive = false
        // this.isPopup = false

        this.$emit('change', asset)
    }
}
