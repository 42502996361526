







import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class FileInput extends Vue {
    files: FileList | null = null

    @Prop({ default: false }) multiple!: boolean
    @Prop({ default: 'raw' }) read_type!: string

    oninput(val: File) {
        // @ts-ignore
        let input = this.$refs.input as HTMLInputElement
        this.files = input.files as FileList
        if (this.read_type === 'raw') {
            if (this.multiple) {
                this.$emit('change', this.files)
            } else {
                this.$emit('change', this.files[0])
            }
        } else {
            this.read()
        }
    }

    read() {
        if (!this.files) return

        let parent = this

        let reader = new FileReader() // no arguments
        reader.onload = function () {
            parent.$emit('change', reader.result)
        }
        reader.onerror = function () {
            console.log(reader.error)
        }

        if (this.read_type === 'text') {
            reader.readAsText(this.files[0])
        }
    }

    clear() {
        let input = this.$refs.input as HTMLInputElement
        input.value = ''
        this.files = null
    }

    get fileNum() {
        if (!this.files) return 0
        return this.files.length
    }
}
