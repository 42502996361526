
















import 'reflect-metadata'
import { Vue, Prop, Component } from 'vue-property-decorator'
import NftCard from '@/components/wallet/portfolio/NftCard.vue'
import { NftFamilyDict } from '@/store/modules/assets/types'
import { UTXO } from '@dcomm-tech/dcomm-js/dist/apis/dvm'

import CollectibleFamily from '@/components/misc/BalancePopup/CollectibleFamily.vue'
@Component({
    components: {
        NftCard,
        CollectibleFamily,
    },
})
export default class CollectibleTab extends Vue {
    @Prop({ default: [] }) disabledIds!: string[]
    get isEmpty(): boolean {
        // return this.$store.getters.walletNftUTXOs.length === 0
        return this.$store.state.Assets.nftUTXOs.length === 0
    }

    get nftFamsDict(): NftFamilyDict {
        return this.$store.state.Assets.nftFamsDict
    }

    isNftUsed(utxo: UTXO) {
        return this.disabledIds.includes(utxo.getUTXOID())
    }

    selectNft(nft: UTXO) {
        this.$emit('select', nft)
    }
}
