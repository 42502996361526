







import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'
import DcommAsset from '@/js/DcommAsset'

@Component
export default class BalanceRow extends Vue {
    @Prop() asset!: DcommAsset

    get name() {
        let name = this.asset.name
        return name
    }

    get symbol() {
        let sym = this.asset.symbol
        return sym
    }
}
