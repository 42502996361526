


































import 'reflect-metadata'
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator'

import { BN } from '@dcomm-tech/dcomm-js'
// import Big from 'big.js';
import Dropdown from '@/components/misc/Dropdown.vue'
// import BigNumInput from "@/components/misc/BigNumInput";

// @ts-ignore
import { BigNumInput } from '@dcomm-tech/vue-components'
import DcommAsset from '@/js/DcommAsset'
import { ICurrencyInputDropdownValue } from '@/components/wallet/transfer/types'
import { IWalletAssetsDict, IWalletBalanceDict, priceDict } from '@/store/types'

import BalanceDropdown from '@/components/misc/BalancePopup/BalanceDropdown.vue'
import { dvm } from '@/DCOMM'
import Big from 'big.js'
import { bnToBig } from '@/helpers/helper'
interface IDropdownValue {
    label: string
    key: string
    data: any
    disabled: boolean
}

@Component({
    components: {
        Dropdown,
        BigNumInput,
        BalanceDropdown,
    },
})
export default class CurrencyInputDropdown extends Vue {
    amount: BN = new BN(0)
    asset_now: DcommAsset = this.walletAssetsArray[0]

    @Prop({ default: () => [] }) disabled_assets!: DcommAsset[]
    @Prop({ default: '' }) initial!: string
    @Prop({ default: false }) disabled!: boolean

    $refs!: {
        bigIn: BigNumInput
    }

    mounted() {
        if (this.isEmpty) return
        if (this.initial) {
            let initialAsset = this.walletAssetsDict[this.initial]
            this.drop_change(initialAsset)
        } else {
            this.drop_change(this.walletAssetsArray[0])
        }
    }

    @Watch('asset_now')
    drop_change(val: DcommAsset) {
        this.asset_now = val
        this.$refs.bigIn.clear()
        // this.amount_in(new BN(0))
        this.onchange()
    }

    get stepSize() {
        if (this.denomination > 3) {
            let stepNum = Math.pow(10, this.denomination - 2)
            return new BN(stepNum.toString())
        } else {
            let stepNum = Math.pow(10, this.denomination)
            return new BN(stepNum.toString())
        }
    }
    maxOut() {
        // @ts-ignore
        this.$refs.bigIn.maxout()
    }

    amount_in(val: BN) {
        this.amount = val
        this.onchange()
    }

    // onchange event for the Component
    @Emit('change')
    onchange(): ICurrencyInputDropdownValue {
        return {
            asset: this.asset_now,
            amount: this.amount,
        }
    }

    onfocus() {
        console.log('focus')
    }

    get amountUSD(): Big {
        let usdPrice = this.priceDict.usd
        let bigAmt = bnToBig(this.amount, this.denomination)
        let usdBig = bigAmt.times(usdPrice)
        return usdBig
    }

    get isEmpty(): boolean {
        if (this.walletAssetsArray.length === 0) {
            return true
        } else {
            return false
        }
    }

    get isDcm(): boolean {
        if (this.asset_now.id === this.dcmAsset?.id) return true
        return false
    }

    get display(): string {
        return ''
    }

    get placeholder(): string {
        if (this.isEmpty || !this.asset_now) return '0.00'
        let deno = this.asset_now.denomination
        let res = '0'
        if (deno > 2) {
            res = '0.00'
        }
        return res
    }

    get denomination(): number {
        if (!this.asset_now) return 0
        return this.asset_now.denomination
    }

    get walletAssetsArray(): DcommAsset[] {
        // return this.$store.getters.walletAssetsArray
        return this.$store.getters['Assets/walletAssetsArray']
    }

    get walletAssetsDict(): IWalletAssetsDict {
        // return this.$store.getters['walletAssetsDict']
        return this.$store.getters['Assets/walletAssetsDict']
    }

    get dcmAsset(): DcommAsset | null {
        return this.$store.getters['Assets/AssetDCOMM']
    }

    get max_amount(): null | BN {
        if (!this.asset_now) return null
        if (!this.dcmAsset) return null

        let assetId = this.asset_now.id
        let balance = this.walletAssetsDict[assetId]

        let dcmId = this.dcmAsset.id

        // Max amount is BALANCE - FEE for DCM
        if (assetId === dcmId) {
            let fee = dvm.getTxFee()
            // console.log(fee);
            if (fee.gte(balance.amount)) {
                return new BN(0)
            } else {
                return balance.amount.sub(fee)
            }
        }

        if (balance.amount.isZero()) return null
        return balance.amount
    }

    get maxAmountBig(): Big {
        if (!this.max_amount) return Big(0)
        return bnToBig(this.max_amount, this.denomination)
    }

    get priceDict(): priceDict {
        return this.$store.state.prices
    }
}
