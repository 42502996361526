<template>
    <div class="spinner">
        <p class="spinner">
            <img v-if="$root.theme === 'day'" src="../../assets/Loading-dark.svg" />
            <img v-else src="../../assets/Loading-lite.svg" />
        </p>
    </div>
</template>
<script>
export default {}
</script>
<style scoped lang="scss">
.spinner {
    width: 30px;
    height: 30px;
    line-height: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    color: var(--font);
    align-items: center;
}
</style>
