






import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class FungibleRow extends Vue {
    @Prop({ default: false }) isDcomm?: boolean
}
