




import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ImageDayNight extends Vue {
    @Prop() day!: string
    @Prop() night!: string

    get isDay() {
        //@ts-ignore
        return this.$root.theme === 'day'
    }
}
